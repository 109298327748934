//
// Button Mixins
//

// Custom variant
@mixin button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active) {
	@if ($color != null) {
		color: $color;
	}

	@if ($icon-color != null) {
		i {
			color: $icon-color;
		}

		.svg-icon {
			@include svg-icon-color($icon-color);
		}

		&.dropdown-toggle:after {
			color: $icon-color;
		}
	}

	@if ($border-color != null) {
		border-color: $border-color;
	}

	@if ($bg-color != null) {
		background-color: $bg-color;
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:focus:not(.btn-active),
	&:hover:not(.btn-active),
	&:active:not(.btn-active),
	&.active,
	&.show,
	.show > & {
		@if ($color-active != null) {
			color: $color-active;
		}

		@if ($icon-color-active != null) {
			i {
				color: $icon-color-active;
			}

			.svg-icon {
				@include svg-icon-color($icon-color-active);
			}

			&.dropdown-toggle:after {
				color: $icon-color-active;
			}
		}

		@if ($border-color-active != null) {
			border-color: $border-color-active;
		}

		@if ($bg-color-active != null) {
			background-color: $bg-color-active !important;
		}
	}
}
