//
// Topbar
//

// General
.topbar {
	display: flex;
	align-items: center;
	width: 100%;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.toolbar {
		flex-direction: column;
		background: get($header-config, bg-color);
	}
}
