//
// Select2 Mixins
//

@mixin select2-multiple-size($input-padding-y, $input-height-border, $choice-font-size, $choice-border-radius, $choice-padding-y, $choice-padding-x, $choice-margin-y, $choice-margin-x, $remove-size, $remove-margin-x) {
	$calculated-input-padding-y: calc((#{$input-padding-y * 2} + #{$input-height-border} - #{2 * $choice-padding-y + 2 * $choice-margin-y}) / 2);
	$calculated-selection-margin-y: calc(#{$input-padding-y} - (#{$input-padding-y * 2} + #{$input-height-border} - #{2 * $choice-padding-y + 2 * $choice-margin-y}) / 2);

	padding-top: $calculated-input-padding-y;
	padding-bottom: $calculated-input-padding-y;

	// Choice
	.select2-selection__choice {
		border-radius: $choice-border-radius;
		padding: $choice-padding-y $choice-padding-x;
		margin: $choice-margin-y $choice-margin-x $choice-margin-y 0;

		.select2-selection__choice__remove {
			height: $remove-size;
			width: $remove-size;
			margin-right: $remove-margin-x;
		}

		.select2-selection__choice__display {
			margin-left: 2 * $remove-size;
			font-size: $choice-font-size;
		}
	}

	// Search input
	.select2-search.select2-search--inline {
		.select2-search__field {
			padding-top: 0;
			padding-bottom: 0;
			margin-top: $calculated-selection-margin-y;
			margin-bottom: $calculated-selection-margin-y;
		}
	}
}
