//
// Layout Config
//

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page bg
$page-bg: if(isDarkMode(), #151521, $white) !default;

// Content border radius
$content-border-radius: 1.5rem !default;

// Content Spacing
$content-spacing: (
	desktop: 30px,
	// Padding for desktop mode
	tablet-and-mobile: 15px // Padding for tablet and mobile modes,,,,
) !default;

// Header
$header-config: (
	height: (
		desktop: 98px,
		tablet-and-mobile: 55px,
	),
	bg-color: if(isDarkMode(), #151521, $white),
	z-index: 100,
) !default;

// Toolbar
$toolbar-config: (
	z-index: 99,
	height: 60px,
	bg-color: if(isDarkMode(), #151521, $white),
	box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.05)),
) !default;

// Aside
$aside-config: (
	width: 300px,
	// Aside width for desktop mode
	z-index: 101,
	// Aside's z-index property
	transition-speed: 0.3s,
	// transition speed
	padding-x: 25px,
	footer-padding-x: 15px,
	menu-padding-x: 10px,
	menu-indention: 0.75rem,
	bg-color: if(isDarkMode(), #1e1e2d, $gray-100),
	border-color: if(isDarkMode(), 1px solid #2d2d43, #e0e0de),
	scrollbar-color: if(isDarkMode(), $gray-300, darken($gray-100, 4%)),
	scrollbar-hover-color: if(isDarkMode(), lighten($gray-300, 3%), darken($gray-100, 5%)),
) !default;

// Aside
$footer-config: (
	bg-color: if(isDarkMode(), #151521, $white),
) !default;
